<template>
  <quill-editor
      ref="myQuillEditor"
      :value="value"
      :options="editorOption"
      @change="onEditorChange($event)"
  />
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

import {quillEditor} from 'vue-quill-editor'

export default {
  props: {
    value: {type: String}
  },
  components: {
    quillEditor
  },
  data() {
    return {
      editorOption: {
        placeholder: '본문을 입력하세요.',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['color', 'background'],
            ['link', 'image'],
            ['clean'],
          ],
        },
        theme: 'snow',
      },
    }
  },
  methods: {
    onEditorChange({quill, html, text}) {
      this.$emit('input', html)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>